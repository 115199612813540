import { lazy } from "react";
import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Anasayfa = lazy(() => import("../views/Anasayfa.js"));
const Login = lazy(() => import("../views/ui/Login.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const KiraciEkle = lazy(() => import("../views/ui/KiraciEkle"));
const SozlesmeDuzenle = lazy(() => import("../views/ui/SozlesmeDuzenle"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const KiraSozlesmeleri = lazy(() => import("../views/ui/KiraSozlesmeleri"));
/*****Routes******/

const isAuthenticated = () => {
  return secureLocalStorage.getItem("jwt_token") !== null;
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Login />;
};
const ThemeRoutes = [
  { path: "/", exact: true, element: <Login /> },
  { path: "/Login", exact: true, element: <Login /> },
  { 
    path: "/Anasayfa", 
    exact: true, 
    element: <FullLayout />, 
    children: [
      { path: "", element: <Anasayfa /> }
    ] 
  },
  {
    path: "/",
    element: <FullLayout />,
    children: [
      // { path: "/", element: <Navigate to="/Anasayfa" /> },
      // { path: "/Anasayfa", exact: true, element:<Anasayfa /> },
      { path: "/about", exact: true, element: <PrivateRoute element={<About />} /> },
      { path: "/alerts", exact: true, element: <PrivateRoute element={<Alerts />} /> },
      { path: "/KiraciEkle", exact: true, element: <PrivateRoute element={<KiraciEkle />} /> },
      { path: "/KiraSozlesmeleri", exact: true, element: <PrivateRoute element={<KiraSozlesmeleri />} /> },
      { path: "/SozlesmeDuzenle", exact: true, element: <PrivateRoute element={<SozlesmeDuzenle />} /> },
      { path: "/badges", exact: true, element: <PrivateRoute element={<Badges />} /> },
      { path: "/buttons", exact: true, element: <PrivateRoute element={<Buttons />} /> },
      { path: "/cards", exact: true, element: <PrivateRoute element={<Cards />} /> },
      { path: "/grid", exact: true, element: <PrivateRoute element={<Grid />} /> },
      { path: "/table", exact: true, element: <PrivateRoute element={<Tables />} /> },
      { path: "/forms", exact: true, element: <PrivateRoute element={<Forms />} /> },
      { path: "/breadcrumbs", exact: true, element: <PrivateRoute element={<Breadcrumbs />} /> },
    ],
  },
];

export default ThemeRoutes;

  // "homepage": "./",