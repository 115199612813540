import { useRoutes, Navigate, useLocation } from "react-router-dom";
import Themeroutes from "./routes/Router";
import React, { useState, useEffect, lazy } from 'react';
const App = () => {
  const routing = useRoutes(Themeroutes);
  const location = useLocation();

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => window.location.hash = '', []);
  return <div className="dark">{routing}</div>;
};

export default App;
